@import './mixins';
$border-radius: 20px;

.ac-contactus {
    @include background_linear;
    padding: 60px 0 200px 0;

    font-size: 18px;

    &-picture {
        width: 100%;
        height: auto;
        text-align: center;
    }

    &-title {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 30px;
        color: var(--var-color-violet-title);
    }

    input,
    textarea {
        width: 100%;
        margin: 0 0 8px;
        padding: 18px 26px;

        background: rgba(255,255,255,.1);
        border: 1px solid rgba(255,255,255,.5);
        border-radius: $border-radius;

        color: #fff;
        font-weight: 500;
        letter-spacing: -.01em;
        
        transition: .3s ease-in-out;

        &:focus {
            background: rgba(255,255,255,1);
            color: hsl(0, 0%, 7%);
            outline: none;

            &::placeholder {
                color: hsl(0, 0%, 7%);
                opacity: .8;
            }
        }

        &::placeholder {
            font-size: .9em;
            font-weight: 400;
            color: #fff;
        }
    }

    button {
        position: relative;
        padding: 14px 48px;
        margin: 25px 0 0;

        font-weight: 700;
        color: #000;
        background-color: #fff;

        outline: 0;
        
        border: 1px solid rgba(255,255,255,.5);
        border-radius: $border-radius;
        overflow: hidden;

        transition: .3s ease-in-out;

        &>span {
            position: relative;
            z-index: 1;
        }

        @include button-hover-transition;
    }

    &-description {
        color: hsl(0deg, 0%, 85%);
    }

    &-list {
        color: hsl(0deg, 0%, 100%);
    }

    &-message-sent {
        margin-top: 1.5em;
        padding: 1em;

        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: $border-radius;
        overflow: hidden;

        background: rgba(255, 255, 255, 0.1);

        display: flex;
        justify-content: center;
        align-items: center;

        > p {
            display: block;
            margin: 0;
            padding: 0;
        }

        &.success {
            background-color: rgba(64, 255, 0, 0.6);
            color: rgba(60, 240, 0, 1);
        }
        &.danger {
            background-color: rgba(255, 0, 0, 0.2);
            color: red;
        }
    }
}

