@import '../../assets/css/mixins';

$button_color_connect: hsl(0, 0%, 60%);

.ac-navbar {

    position: fixed;
    top: 0;
    z-index: 9999;
    
    width: 100%;
    padding: 18px 0;

    transition: all .3s ease-in-out;

    &.scrolled {
        padding: 0;
        background-color: rgba($color: #fff, $alpha: .9)
    }

    &-link {
        font-weight: 400;
        letter-spacing: -.05px;
        padding: 0 25px;
        font-size: 18px;
        opacity: .75;

        &:hover,
        &.active {
            opacity: 1;
        }
    }

    &-social {
        display: flex;
        justify-content: center;
        align-items: center;

        gap: .5em;
    }

    &-connect {

        display: inline-block;

        &__button {
            position: relative;
            padding: 7px 24px;
            
            border: 1px solid var(--var-color-violet);
            background-color: transparent;
            
            color: var(--var-color-violet);
            font-weight: 700;
            font-size: 18px;
            
            transition: 0.3s ease-in-out;

            &>span {
                position: relative;
                z-index: 1;
            }

            @include button-hover-transition;
        }
    }

    .navbar-toggler {

        padding: 8px;
        border-color: var(--var-color-violet);

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }

        &-icon {
            position: relative;
            top: -2px;

            width: 24px;
            height: 16px;

            background-image: none;
            border-bottom: 2px solid var(--var-color-violet);

            transition: all 300ms linear;

            &:focus {
                border-bottom: 2px solid #fff;
            }

            &:after,
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                z-index: 2;

                width: 24px;
                height: 2px;

                background-color: var(--var-color-violet);

                transition: all 300ms linear;
            }

            &:after {
                top: 7px;
            }
        }

        &[aria-expanded="true"] {
            .navbar-toggler-icon {
                border-color: transparent;

                &:after {
                    transform: rotate(45deg);
                    background-color: #fff;
                    height: 2px;
                }

                &:before {
                    transform: translateY(8px) rotate(-45deg);
                    background-color: #fff;
                    height: 2px;
                }
            }
        }
    }

    &-link {
        text-align: center;
    }

    &-collapse {
        display: flex;
        gap: 1em;
        justify-content: flex-end;

        &.collapse.show {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: 100%;
            right: 0;
            left: 0;

            background: hsla(290, 35%, 45%, 0.7);  /* fallback for old browsers */
            // background: -webkit-linear-gradient(161deg, hsla(290, 35%, 45%, 0.7), hsla(290, 45%, 25%, 0.7), hsla(0, 0%, 0%, 0.7));  /* Chrome 10-25, Safari 5.1-6 */
            // background: linear-gradient(161deg, hsla(290, 35%, 45%, 0.7), hsla(290, 45%, 25%, 0.7), hsla(0, 0%, 0%, 0.7)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            
            border-bottom-left-radius: 64px;
            border-bottom-right-radius: 64px;

            padding: 1.5em 1em;

            color: #fff;

            backdrop-filter: saturate(180%) blur(20px);

            .ac-navbar-menu {
                min-width: 70%;
            }

            .ac-navbar-connect {
                @include background_linear;
                transition: 0.3s ease-in-out;

                &__button {
                    color: #fff !important;
                }
            }
            
            .ac-navbar-link {
                padding: 15px 25px;
                color: hsl(0deg, 0%, 90%);
                
                &:not(&:last-child) {
                    border-bottom: 1px solid hsl(320deg, 50%, 50%);
                }

                &.active {
                    color: hsl(0deg, 0%, 100%);
                }
            }

            .ac-navbar-text {
                padding: 10px 25px;
            }

            .ac-navbar-connect {
                text-align: center;
                margin-bottom: 1em;
            }
        }
    }

}