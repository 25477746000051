$icon_width: 40px;

.ac-social-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $icon_width;
    height: $icon_width;
    
    line-height: 1;

    background: rgba(217,217,217, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;

    &::before {
        content: "";
        position: absolute;
        width: $icon_width + 10;
        height: $icon_width + 10;

        background-color: #000;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.3s ease-in-out;
    }

    &:hover {
        &::before {
            transform: scale(1);
        }

        &>img {
            filter: invert(100%);
        }
    }

    &>img {
        width: 60%;
        z-index: 1;
        transition: 0.3s ease-in-out;
    }
}