.ac-footer {
    padding: 500px 0 50px 0;
    
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(45.45deg, #302b63 -5.45%, #0f0c29, #302b63 105.45%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(45.45deg, #302b63 -5.45%, #0f0c29, #302b63 105.45%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    p {
        margin-top: 20px;
        
        color: #B8B8B8;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;
    }

    &-social {
        &-icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            gap: .5em;
        }
    }
}