@import './mixins';

.ac-about-small {
    position: relative;
    height: 0;

    h3 {
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1.2em;
        font-size: 24px;

        span {
            font-size: 1.4em;
            color: #aa367c; //var(--var-color-violet);
        }
    }

    &__container {
        margin-top: -10%;

        background: #fff;
        border-radius: 55px;
        color: hsl(0, 0%, 7%);
        padding: 80px 120px;

        p {
            margin: 0;
            line-height: 1.6em;
            color: hsl(0, 0%, 7%);
        }
    }

    &__cdata {
        color: hsl(0, 0%, 7%); 

        h4 {
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 2em;
            font-size: 22px;
        }
    }

    &__list {
        margin-bottom: 1em;

        > strong {
            font-size: 18px;
            font-weight: 400;
            text-decoration: underline;
        }
    }

    &__rekvi {
        list-style: none;
        margin: 0;
        padding: 0;

        li:first-child > strong {
            font-size: 18px;
            font-weight: 400;
            text-decoration: underline;
        }

        li {
            margin: 0 0 .3em;
            padding: 0;
        }
    }

    &__link {
        text-decoration: none;
        outline: none;

        color: #000;

        &:hover {
            color: var(--var-color-violet-title);
        }
    }
}