@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;400;600;700;900&family=Open+Sans:wght@300&display=swap');

html, body {
    height: 100%;
}

body {
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;

    background: rgb(255, 255, 255);
}

:root {
    --var-color-violet: hsl(280, 50%, 45%);
    --var-color-violet-title: hsl(280deg 90% 60%);
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

.ac-color-violet {
    color: var(--var-color-violet);
}

.ac-color-violet-title {
    color: var(--var-color-violet-title);
}