@import '../../assets/css/mixins';

$size: 40px;

.ac-navbar-fly {
    position: fixed;
    right: 0;
    bottom: 20px;
    left: 0;

    display: flex;
    justify-content: center;

    z-index: 9999;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5em;

        width: auto;
        padding: .6em 1.4em;

        background: rgba(0,0,0,.2);
        border-radius: $size;

        backdrop-filter: saturate(180%) blur(20px);

        &>a {
            position: relative;
            z-index: 10;

            width: $size;
            height: $size;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: inherit;

            margin: 0;
            padding: 0;

            &::before {
                content: '';
                position: absolute;
                z-index: -1;

                width: $size;
                height: $size;

                border-radius: 50%;

                transform: scale(0);
                transition: 0.3s ease-in-out;

                background-color:  hsla(0, 0%, 50%, 15%)
            }

            &:hover,
            &.active {
                &::before {
                    transform: scale(1);
                }
            }

            &.active {
                &>svg {
                    color: hsl(0, 0%, 85%, 60%) !important;
                }

                &::before {
                    background-color: hsla(0, 0%, 0%, 40%);
                }
            }

            &>svg {
                color: hsl(0, 0%, 85%) !important;
            }
        }
    }
}