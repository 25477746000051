
@mixin background_linear($angle: 91.333deg) {
    background: rgb(74, 47, 189);
    background: -webkit-linear-gradient($angle, rgb(170, 54, 124) -14.333%, rgb(74, 47, 189) 114.333%);
    background: linear-gradient($angle, rgb(170, 54, 124) -14.333%, rgb(74, 47, 189) 114.333%);
}

@mixin button-hover-transition() {
    &:hover,
    &.active {
        color: #fff;

        &::before {
            width: 100%;
            left: 0;
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: 0;
        
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;

        @include background_linear;

        transition: 0.3s ease-in-out;
    }
}