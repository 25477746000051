@import './mixins';

.ac-banner {
    margin-top: 0;
    padding: 180px 0 100px 0;

    /*background: transparent url('../images/background-ametist.png') no-repeat;*/
    /*background-position: 80% bottom;*/
    /*background-size: 550px auto;*/

    &-content {
        position: relative;
    }

    &-tag-line {
        display: inline-block;
        padding: 8px 10px;
        margin-bottom: 40px;

        font-weight: bold;
        letter-spacing: -.01em;
        
        @include background_linear;

        color: #fff;
    }

    &-title {
        display: block;
        margin-bottom: 20px;

        font-size: 55px;
        font-weight: 700;
        letter-spacing: -.05em;
        line-height: 1em;

        color: var(--var-color-violet);
    }

    &-description {
        color: var(--bs-code-color);
        font-weight: 700;
        font-size: 30px;
        margin-top: 40px;
        letter-spacing: -.03em;
        line-height: 1.5em;
    }

    &-text-rotate {
        display: inline-block;
        color: #000;
    }

    &-text-wrap {
        border-right: .3em solid #666;
        padding-right: .1em;
    }

    &-button-connect {
        display: flex;
        align-items: center;
        margin-top: 60px;

        gap: 10px;

        color: var(--var-color-violet);
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -.03em;

        outline: none;
        border: none;
        background: none;

        &>svg {
            font-size: 25px;
            line-height: 1;
            margin-left: 10px;

            transition: .3s ease-in-out;
        }

        &:hover {
            &>svg {
                margin-left: 25px;
            }
        }
    }

    &-picture {
        position: absolute;
        
        bottom: -60px;
        right: 5%;

        animation: updown 3s linear infinite;

        z-index: -1;

        text-align: center;



        img {
            width: 100%;
            max-width: 550px;
            height: auto;
        }
    }
}

@media (max-width: 990px) {
    .ac-banner {
        &-picture {
            right: 0;
        }
    }
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}