@import './mixins';

.ac-projects {
    position: relative;
    padding: 80px 0;

    background: #000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(173deg, #000, #4b2157 90%, #924b9b);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(173deg, #000, #4b2157 90%, #924b9b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    color: hsl(0, 0%, 60%);

    h5 {
        font-size: 26px;
        color: hsl(0, 0%, 90%);
        color: var(--var-color-violet-title);
        margin-bottom: 1em;
    }

    &-title {
        font-size: 45px;
        font-weight: 700;
        text-align: center;
        color: var(--var-color-violet-title);
    }

    &-description {
        width: 60%;
        margin: 14px auto 30px auto;
        
        color: hsl(0, 0%, 75%);
        text-align: center;
        letter-spacing: -.01em;
        line-height: 1.5em;
        font-size: 18px;
    }

    &-servicelist {
        color: #fff;

        > div {
            &:first-child {
                > div {
                    border-top: none;
                }
            }

            &:last-child {
                > div {
                    border-bottom: none;
                }
            }

            > div {
                border: 1px solid hsl(290deg, 45%, 25%);
                border-right: none;

                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:first-child {
                    border-left: none;
                }
            }
        }
    }

    .nav {
        &.nav-pills {
            width: 60%;
            margin: 0 auto;
            overflow: hidden;

            .nav-item {
                width: 33.3332%;

                border: 1px solid hsl(0, 0%, 10%);
                background-color: hsl(0, 0%, 5%);

                $border-radius: 30px;
                overflow: hidden;
    
                &:first-child {
                    border-right: none;
                    border-radius: $border-radius 0 0 $border-radius; 
                }
    
                &:last-child {
                    border-left: none;
                    border-radius: 0 $border-radius $border-radius 0;
                }
            }
        }

        .nav-link {
            position: relative;
            z-index: 0;
            width: 100%;
            padding: 17px 0;

            color: #fff;
            background-color: transparent;
            border-radius: 0;
            
            font-size: 17px;
            letter-spacing: 0.8px;
            font-weight: 500;
            text-align: center;
            
            transition: 0.3s ease-in-out;

            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 100%;
    
                background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
                top: 0;
                left: 50%;
                z-index: -1;
                transition: 0.3s ease-in-out;
            }
    
            &.active {
                &::before {
                    width: 100% !important;
                    left: 0;
                }
            }
        }
    }

    .project-image-box {
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        margin-bottom: 24px;
        
        &::before {
            content: "";
            background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
            opacity: 0.85;
            position: absolute;
            width: 100%;
            height: 0;
            transition: 0.4s ease-in-out;
        }

        &:hover {
            &::before {
                height: 100%;
            }

            .project-content {
                top: 50%;
                opacity: 1;
            }
        }
    }

    .project-image-picture {
        width: 100%;
        height: auto;
    }

    .project-content {
        position: absolute;
        top: 65%;
        left: 50%;
        width: 100%;
        opacity: 0;
        padding: 20px;
        
        text-align: center;
        transform: translate(-50%, -50%);
        transition: 0.5s ease-in-out;

        color: hsl(0, 0%, 90%)
    }

    .project-title {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0.8px;
        line-height: 1.1em;
    }

    .project-description {
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.8px;
    }
}