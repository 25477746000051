
$logotype_dimension: 40px;
$size: 26px;

.ac-logotype {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    letter-spacing: -0.01em;

    font-size: $size;
    line-height: $size;

    text-transform: uppercase;

    >span {
        display: block;
        margin: 0;
        padding: 0;
    }

    &-first {
        font-weight: 700;
    }

    &-last {
        font-size: .8em;
        color: var(--var-color-violet);
    }

    &-picture {
        position: relative;
        z-index: 1;
        
        width: $logotype_dimension*0.8;
        min-width: $logotype_dimension*0.8;
        height: $logotype_dimension;
        

        &:before {
            content: "";
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            background: transparent center -0.5px no-repeat;
            background-image: url('../images/ametist-it_logotype256.png');
            background-size: auto ($logotype_dimension * .95);
        }
    }

    &.black-and-white {
        color: #fff !important;

        .ac-logotype-first,
        .ac-logotype-last {
            color: #fff;
        }

        .ac-logotype-picture {
            filter: grayscale(1) invert(1);
        }
    }
}