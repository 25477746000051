.ac-about {
    position: relative;
    padding: 0 0 50px;
    
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(161deg, #924b9b, #4b2157, #000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(161deg, #924b9b, #4b2157, #000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    &-content {
        background-color: hsl(0deg, 0%, 10%);
        border-radius: 64px;
        text-align: center;
        padding: 60px 50px;
        margin-top: -60px;
    }

    &-title {
        font-size: 45px;
        font-weight: 700;
        color: var(--var-color-violet-title);
        margin: 20px 0 10px;
    }

    p, &-description {
        color: hsl(0deg, 0%, 80%);
        font-size: 18px;
        letter-spacing: .01em;
        line-height: 1.5em;
        margin: 14px 0 20px 0;
    }
}